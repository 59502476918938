<template>
  <div class="team-member-badge d-flex align-items-center">
    <b-avatar variant="info" :src="memberDetails.profile_image_url"></b-avatar>
    <div class="team-member-heading">
      <h4 :title="memberDetails.username">
        {{ memberDetails.username }}
      </h4>
      <!-- <p>{{ memberDetails.last_activity_at }}</p> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    memberDetails: Object
  }
};
</script>
<style lang="scss" scoped>
.team-member-badge {
  .b-avatar {
    margin-top: 3px;
  }
  .team-member-heading {
    width: calc(100% - 50px);
    margin-left: 1rem;
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.1px;
      color: #44464e;
      margin-bottom: 0px;

      @include text-ellipsis;
    }
    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #44464e;
    }
  }
}
[dir="rtl"] {
  .team-member-badge {
    .team-member-heading {
      margin-right: 1rem;
      margin-left: auto;
      text-align: right;
    }
  }
}
</style>
